const common = {
  variant: 'header4Med',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 25,
  border: 0,
  height: 50,
  py: 0,
  px: 40,
  sx: {
    cursor: 'pointer',
    appearance: 'none',
    textDecoration: 'none',
    '&[disabled]': { cursor: 'auto' },
  },
};

const buttonTheme = {
  blue: {
    ...common,
    bg: 'indigo',
    color: 'white',
    sx: {
      ...common.sx,
      '&:hover': {
        bg: 'accentBlue',
      },
    },
  },
  white: {
    ...common,
    bg: 'white',
    color: 'black',
    sx: {
      ...common.sx,
      '&:hover': {
        bg: 'yellow',
      },
    },
  },
  yellow: {
    ...common,
    bg: 'yellow',
    color: 'black',
    sx: {
      ...common.sx,
      '&:hover': {
        bg: 'accentBlue',
        color: 'white',
      },
    },
  },
  unset: {
    p: 0,
    m: 0,
    bg: 'transparent',
    color: 'inherit',
    width: 'auto',
    sx: {
      cursor: 'pointer',
      border: 'none',
      '-webkit-font-smoothing': 'inherit',
      '-moz-osx-font-smoothing': 'inherit',
      '-webkit-appearance': 'none',
    },
  },
};

export default buttonTheme;
