import { Link as GatsbyLink } from 'gatsby';
import {
  alignContent,
  alignItems,
  border,
  color,
  compose,
  flexbox,
  grid,
  justifyContent,
  justifyItems,
  layout,
  position,
  space,
  typography,
  variant,
} from 'styled-system';
import { css } from '@styled-system/css';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Box from './Box';
import Button from './Button';
import variants from './theme/theme-variants';

export const Flex = React.forwardRef((props, ref) => (
  <Box ref={ref} display="flex" alignItems="center" {...props} />
));

export const Grid = styled(Box)(
  compose(grid, alignItems, alignContent, justifyItems, justifyContent)
);
Grid.defaultProps = {
  display: 'grid',
};

export const H1 = (props) => <Box as="h1" variant="largeTitle" {...props} />;

export const H2 = (props) => <Box as="h2" variant="title" {...props} />;

export const H3 = (props) => <Box as="h3" variant="header1" {...props} />;

export const H4 = (props) => <Box as="h4" variant="header2" {...props} />;

export const H5 = (props) => <Box as="h5" variant="header3" {...props} />;

export const H6 = (props) => <Box as="h6" variant="header4" {...props} />;

export const P = (props) => <Box as="p" variant="body2" {...props} />;

export const Span = (props) => <Box as="span" {...props} />;

const linkSx = (decorate) => ({
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: decorate ? 'underline' : 'none',
  },
});

const StyledLink = styled(
  ({ lineHeight, alignItems: _ai, justifySelf: _js, gridColumn: _gc, ...props }) => (
    <GatsbyLink {...props} />
  )
)(
  compose(color, space, typography, layout, position, border, flexbox, grid, variant({ variants })),
  (props) => css(props.sx)(props.theme)
);
export const Link = React.forwardRef(({ decorate, to, sx, ...props }, ref) => {
  return (
    <StyledLink
      innerRef={ref}
      to={to}
      color="inherit"
      sx={{ ...linkSx(decorate), ...sx }}
      {...props}
    />
  );
});
Link.propTypes = {
  to: PropTypes.string.isRequired,
  sx: PropTypes.objectOf(PropTypes.any),
  decorate: PropTypes.bool,
};
Link.defaultProps = {
  sx: undefined,
  decorate: true,
};

export const A = ({ sx, decorate, ...props }) => {
  return (
    <Box
      as="a"
      color="inherit"
      sx={{
        ...linkSx(decorate),
        ...sx,
      }}
      {...props}
    />
  );
};
A.propTypes = {
  sx: PropTypes.objectOf(PropTypes.any),
  decorate: PropTypes.bool,
};
A.defaultProps = {
  sx: undefined,
  decorate: true,
};

export const Outer = (props) => <Box display="flex" justifyContent="center" {...props} />;

export const Inner = (props) => (
  <Box
    as="section"
    maxWidth={1000}
    m="0 auto"
    p="100px 0px"
    width={['calc(100% - 40px)', 'calc(100% - 70px)']}
    {...props}
  />
);

export const DocPage = styled.div`
  margin: 0 auto;
  max-width: 800px;

  h2 {
    font-size: 16px;
    color: ${(props) => props.theme.colours.black};
    font-weight: 500;
    margin: 40px 0 20px;
  }
  p {
    margin-top: 1em;
    font-size: 1.5rem;
    line-height: 1.7em;
    letter-spacing: 0;
  }
  li {
    color: inherit;
    font-size: 1.5rem;
    line-height: 1.7em;
    margin-bottom: 8px;
  }
  ol,
  ul {
    margin: 0 0 12px;
  }
  table {
    font-size: 1.5rem;
    td,
    th {
      padding: 10px;
    }
    th {
      background-color: ${(props) => props.theme.colours.indigo};
      color: ${(props) => props.theme.colours.white};
    }
  }
`;

export { Box, Button };
