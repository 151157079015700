import React from 'react';

import Svg from './Svg';

const Logo = (props) => (
  <Svg viewBox="0 0 117 171" fill="none" width="1em" height="1em" {...props}>
    <path
      d="M16.06 22.17l.216.215c3.231 9.04 6.247 17.004 9.909 23.247 1.723 3.013 3.662 5.811 5.601 7.748 1.939 1.938 3.878 3.229 5.601 4.09.646.215 1.077.43 1.723.646-.646-2.583-.646-4.736-.646-5.166v-.861c.215-10.762 8.832-19.372 19.604-19.372h.215c7.54 0 14.218 4.305 17.45 10.977 3.877 8.18 13.786 11.408 21.757 7.534 8.186-3.875 11.417-13.776 7.54-21.74C96.197 11.408 78.317.215 58.498 0h-.646C41.675 0 24.691 9.848 15.414 21.74c.215 0 .43.215.646.43zM82.316 164.168c-.43.215-.862 0-1.077-.431v-.43c.861-4.735 1.939-8.825 2.585-12.915.646-4.089 1.077-7.964 1.077-11.623.215-7.318-1.077-13.56-3.231-18.08-1.077-2.368-2.37-4.09-3.878-5.597-.215 3.229-1.077 6.458-1.723 8.395-3.447 7.318-10.987 12.054-18.958 12.054h-.215c-8.617-.216-16.587-5.597-19.603-13.776-3.232-9.255-13.357-13.776-22.404-10.332-9.048 3.229-13.787 13.345-10.34 22.386 7.97 21.524 28.866 36.161 51.916 36.591h.646c9.48 0 18.742-2.368 26.713-6.888-.431.215-1.077.431-1.508.646z"
      fill="#22BBE0"
    />
    <path
      d="M15.896 21.123c3.232 9.04 6.609 17.981 10.27 24.223 1.724 3.014 3.663 5.812 5.602 7.749 1.939 1.937 3.877 3.229 5.6 4.09 1.724.86 3.232 1.29 5.171 1.721 1.723.43 3.878.861 6.247 1.077 2.37.215 4.955.645 7.97.645l2.801.216c1.508 0 2.8.215 4.309.43l4.308.646c1.508.215 3.016.646 4.309.86 11.632 2.799 23.48 9.256 31.882 19.373 4.093 4.95 7.325 10.762 9.479 16.789 1.939 6.026 2.8 12.269 2.585 18.295-.431 11.839-5.17 22.386-11.418 30.134-6.247 7.964-14.256 13.744-23.089 17.187-.43.216-1.141.554-1.141.554 0-.215.319-1.382.319-1.597.861-4.736 1.938-8.825 2.585-12.915.646-4.09 1.077-7.964 1.077-11.623.215-7.318-1.077-13.56-3.232-18.081-2.154-4.52-5.17-7.318-8.186-9.04-3.015-1.937-6.678-3.013-10.986-3.874-1.077-.215-2.37-.215-3.447-.431h-1.939l-3.447-.215-4.093-.646-4.093-.645c-2.8-.431-5.6-1.292-8.401-1.937-5.6-1.938-11.417-4.52-16.588-8.18-5.385-3.874-10.124-8.825-13.571-14.421C3.332 75.911 1.178 69.884.532 64.072c-.862-5.811-.647-11.408.43-16.358 1.078-5.166 2.801-9.901 5.17-14.206C8.065 30 11.5 25.5 15.897 21.123z"
      fill="#2F80ED"
    />
  </Svg>
);

export default Logo;
