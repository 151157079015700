import PropTypes from 'prop-types';
import React from 'react';

import Box from './Box';
import buttonTheme from './theme/button-theme';

const Button = React.forwardRef(({ bVariant, sx, children, ...props }, ref) => {
  const { sx: buttonVariantSx = {}, ...buttonVariant } = buttonTheme[bVariant] || {};
  return (
    <Box
      ref={ref}
      as="button"
      type={bVariant === 'submit' ? 'submit' : 'button'}
      {...buttonVariant}
      sx={{
        ...buttonVariantSx,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
});
Button.propTypes = {
  children: PropTypes.node.isRequired,
  bVariant: PropTypes.string,
  sx: PropTypes.objectOf(PropTypes.any),
};
Button.defaultProps = {
  bVariant: 'blue',
  sx: undefined,
};

export default Button;
