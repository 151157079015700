import PropTypes from 'prop-types';
import React from 'react';

import { Flex } from './styled';
import LogoSvg from '../images/components/logo';
import WordmarkSvg from '../images/components/wordmark';

const Logo = ({ iconOnly = false }) => (
  <Flex>
    <LogoSvg width={21} height={31} />
    {!iconOnly && <WordmarkSvg ml={10} height={19} width={76} />}
  </Flex>
);

Logo.propTypes = {
  iconOnly: PropTypes.bool,
};
Logo.defaultProps = {
  iconOnly: false,
};

export default Logo;
